import { NotificationPreferenceData } from '../../redux/models/settings.models';
import axiosInstance from '../../utils/axios';
import { EntityServiceProvider } from '../base/EntityServiceProvider';

class NotificationPreferenceApiProvider<T> extends EntityServiceProvider<T> {
  getPreferencesByContactId(contactId: string) {
    return this.api.get(`${this.endpoint.entityPath()}/contact/${contactId}`);
  }
}

const NotificationPreferenceApi = new NotificationPreferenceApiProvider<NotificationPreferenceData>(
  'notification-preferences',
  axiosInstance
);

export default NotificationPreferenceApi;