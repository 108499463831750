import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchTripDetails } from '../../redux/slices/trips/trips';
import { selectFleetObject } from '../../redux/slices/settings';
import { CreateReservationRequest } from '../../redux/slices/seaspan/seaspan.models';
import NewJobCard from './components/NewJobCard';
import {
 mapTrailerToRes, mapShipmentsToRes, createReservation, createTripTrailers
} from './seaspan-job.utils';
import { TripDetails } from '../../redux/models/trip.models';
import Loader from '../../components/Loader';
import { createShipmentIdsDropdownOptions } from '../../utils/core.utils';


export default function CreateTripJob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [trip, setTrip] = useState<TripDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTrailerId, setTId] = useState('');
  const [selectedShipmentIds, setShipmentIds] = useState<string[]>([]);
  const fleet = useSelector(selectFleetObject);
  const tripNo = trip?.data?.trip_no || '';
  const moves = trip?.data.moves || [];
  const trailers = createTripTrailers(moves, fleet);
  const shipments = trip?.data.shipments || [];
  const shipmentOptions = createShipmentIdsDropdownOptions(shipments, {}, 'shipment_no');
  const defaultResData = createReservation(trip, fleet);
  const [reservation, setReservation] = useState<CreateReservationRequest>(defaultResData);
  const handleSave = () => {
    navigate(-1);
  }
  const handleselectTrailer = (tId: string) => {
    const trailer = fleet[tId];
    if (trailer) {
      const updates = mapTrailerToRes(reservation, trailer);
      if (updates) setReservation(updates);
    }
    setTId(tId);
  };
  const handleSelectShipments = (sIds: string[]) => {
    const shipData = sIds.flatMap((selId) => {
      const sh = shipments.filter((ship) => ship.entity_id === selId);
      return sh[0];
    }).filter((sh) => sh !== undefined);
    const updates = mapShipmentsToRes(reservation, shipData);
    if (updates) setReservation(updates);
    setShipmentIds(sIds);
    console.log(sIds);
  };

  useEffect(() => {
    const loadTrip = async (tripId: string) => {
      try {
        setIsLoading(true);
        const response = await fetchTripDetails(tripId);
        if (response.status === 200) {
          const tripData = response.data.data;
          const updatedResData = createReservation(trip, fleet);
          const tri = updatedResData.trailer_id || '';
          const ships = updatedResData.shipment_ids || [];
          setTrip(tripData);
          setTId(tri);
          setShipmentIds(ships);
          setReservation(updatedResData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) loadTrip(id);
  }, [id, fleet]);
  return (
    <>
      <h1 className="h3 mb-3" data-cy="trip_title">{`${tripNo} ${t('Job Requests')}`}</h1>
      {isLoading && <Loader />}
      {!isLoading && (
        <NewJobCard
          reservation={reservation}
          tripId={id || ''}
          trailers={trailers}
          trailerId={selectedTrailerId}
          shipmentIds={selectedShipmentIds}
          shipmentOptions={shipmentOptions}
          onSave={handleSave}
          selectTrailer={handleselectTrailer}
          selectShipment={handleSelectShipments}
        />
      )}
    </>
  )
};
