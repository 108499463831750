/* eslint-disable */
import { Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { AppState } from "../../../redux/models/state.models"
import { selectBoard } from "../../../redux/slices/settings"
import AssetTrackerCol from "./AssetTrackerCol"

export default function AssetTrackerBoard() {
  const { boardId } = useParams();
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const addresses = board?.data.addresses || [];
  return (
    <div className="min-vh-100">
      <h3>{board?.data.name || 'No name'}</h3>
      {addresses.length > 0 && (
        <Row xs={1} md={2} lg={3} className="g-4">
          {addresses.map((address) => {
            return (
              <AssetTrackerCol
                key={address.entity_id}
                address={address}
              />
            )
          })}
        </Row>
      )}
      {addresses.length === 0 && (
        <p className="text-center text-muted mt-5">No addresses added to the board.</p>
      )}
    </div>
  )
}