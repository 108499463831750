/* eslint-disable radix */
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSettingByName, selectAddressesObject, selectBoardsObject, selectFleetObject
} from "../../../redux/slices/settings"
import PageFooterButtons from "../../../components/shared/PageFooterBtns";
import BreadCrumbs from "../../../components/shared/breadcrumbs/BreadCrumbs";
import { FLEET_TYPES } from "../../../redux/models/settings.models";
import { Api } from "../../../services/services";
import Analytics from "../../../utils/analytics";
import { errorToast, successToast } from "../../../components/notifications/app-toast";
import { formatEnumName, getFleetDetailRoutes } from "../asset-tracker.utils";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { SETTINGS_NAMES } from "../../../constants/settings-manager";

export default function FleetDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orgCode, boardId, addressId, fleetId } = useParams();
  const boards = useSelector(selectBoardsObject);
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  const address = addresses[addressId || ''];
  const board = boards[boardId || ''];
  const asset = fleet[fleetId || ''];
  console.log(asset);
  const defaultLength = asset?.data?.length || 0;
  const defaultWidth = asset?.data?.width || 0;
  const defaultOverhangFront = asset?.data?.over_length_front || 0;
  const defaultOverhangBack = asset?.data?.over_length_back || 0;
  const defaultGenset = asset?.data?.genset || false;
  const defaultSeaspanUnitType = asset?.data?.seaspan_unit_type || '';
  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(asset?.data?.status || '');
  const [type, setType] = useState(asset?.data?.type || '');
  const [licencePlate, setLicencePlate] = useState(asset?.data?.license_plate || '');
  const [length, setLength] = useState(`${defaultLength}`);
  const [width, setWidth] = useState(`${defaultWidth}`);
  const [overhangFront, setOverhangFront] = useState(`${defaultOverhangFront}`);
  const [overhangBack, setOverhangBack] = useState(`${defaultOverhangBack}`);
  const [genset, setGenset] = useState(defaultGenset);
  const [seaspanUnitType, setSeaspanUnitType] = useState(defaultSeaspanUnitType);
  const name = asset?.data.name || 'No name';
  const handleCancel = () => navigate(-1);
  const handleSave = async () => {
    if (!asset) return;
    try {
      setIsSaving(true);
      const updates = {
        ...asset,
        data: {
          ...asset.data,
          status,
          type,
          length: parseInt(length),
          width: parseInt(width),
          license_plate: licencePlate,
          over_length_front: parseInt(overhangFront),
          over_length_back: parseInt(overhangBack),
          genset,
          seaspan_unit_type: seaspanUnitType
        }
      };
      const response = await Api.Fleet.update(updates);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.FLEET, false));
        successToast(`${name} successfully updated`);
        handleCancel();
      }
    } catch (error) {
      Analytics.capture(error);
      errorToast(`Error updating ${name}`);
    }
  };
  useEffect(() => {
    const stat = asset?.data?.status || '';
    const typ = asset?.data?.type || '';
    const len = asset?.data?.length || 0;
    const wid = asset?.data?.length || 0;
    const overlenFront = asset?.data?.over_length_front || 0;
    const overlenBack = asset?.data?.over_length_back || 0;
    const gen = asset?.data?.genset || false;
    const unitType = asset?.data?.seaspan_unit_type || '';
    setStatus(stat);
    setType(typ);
    setLength(`${len}`);
    setWidth(`${wid}`);
    setOverhangFront(`${overlenFront}`);
    setOverhangBack(`${overlenBack}`);
    setGenset(gen);
    setSeaspanUnitType(unitType);
  }, [asset]);
  return (
    <div>
      {board && address && asset && <BreadCrumbs routes={getFleetDetailRoutes(orgCode, board, address, asset)} />}
      <h3>{`${name}`}</h3>
      <Row>
        <Form.Group className="mb-3">
          <Col xs={12} md={3} className="mb-3 mb-md-0">
            <Form.Label>{t("Status")}</Form.Label>
            <Form.Select title="Status" value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="">Select</option>
              <option value="active">Active</option>
              <option value="maintenance">Maintenance</option>
              <option value="inactive">Inactive</option>
            </Form.Select>
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Trailer Type")}</Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="">Select</option>
              {FLEET_TYPES.map((ty) => (
                <option key={ty} value={ty}>{formatEnumName(ty)}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Licence Plate")}</Form.Label>
            <Form.Control
              type="text"
              value={licencePlate}
              onChange={(e) => setLicencePlate(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Length (ft)")}</Form.Label>
            <Form.Control
              type="number"
              value={length}
              step="1"
              min="0"
              onChange={(e) => setLength(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Width (ft)")}</Form.Label>
            <Form.Control
              type="number"
              value={width}
              step="1"
              min="0"
              onChange={(e) => setWidth(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Over Length Front (ft)")}</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={overhangFront}
              onChange={(e) => setOverhangFront(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Over Length Back (ft)")}</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={overhangBack}
              onChange={(e) => setOverhangBack(e.target.value)}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>{t("Gen Set")}</Form.Label>
            <Form.Check 
              checked={genset}
              onChange={(e) => setGenset(e.target.checked)}
            />
          </Col>
          <Col xs={12} md={3} className="mb-3 mb-md-0">
            <Form.Label>{t("Seaspan Unit Type")}</Form.Label>
            <Form.Select
              title="Seaspan Unit Type"
              value={seaspanUnitType}
              onChange={(e) => setSeaspanUnitType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="AUTO">Automobile</option>
              <option value="BT">B-Train</option>
              <option value="DU">Drop Unit</option>
              <option value="FLAT">Flat</option>
              <option value="LU">LU</option>
              <option value="TANK">Tank</option>
              <option value="VAN">Van</option>
            </Form.Select>
          </Col>
        </Form.Group>
      </Row>
      <Row>
        <PageFooterButtons
          disabled={isSaving}
          page="FleetDetails"
          deleteButton={false}
          handleSave={handleSave}
          handleClose={handleCancel}
          handleDelete={() => {}}
          isSaving={isSaving}
          isEditable
        />
      </Row>
            
    </div>
  )
}