import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Row, Col, Dropdown, Spinner, Stack,
  Card,
} from 'react-bootstrap';
import { Plus } from 'react-feather';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import Permission from '../../../components/shared/permissions/Permission';
import { TripDetails } from '../../../redux/models/trip.models';

const SECTION_TITLE = 'Seaspan';
const NO_SHIPMENTS = 'No TOPS jobs requested';
const NEW_SHIPMENT_OPTION = 'New Job';
const JOB = 'Job';
const page = 'trip';

const WriteShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Write,
};

interface Props {
  tripDetails: TripDetails;
}

export default function SeaspanContainer({ tripDetails }: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const isAddingShipments = false;
  const reqs = tripDetails.data.seaspan_reservation_requests || [];
  const isReqs = reqs.length > 0;
  const handleAddShipment = () => {
    navigate(`/${orgCode}/trips/${tripDetails.entity_id}/seaspan/create-job`);
  };
  return (
    <div className="mt-2 pb-2">
      <Row className="pb-2">
        <Col>
          <h4>
            {SECTION_TITLE}
          </h4>
        </Col>
        <Col>
          <Permission resources={WriteShipmentPermissions}>
            <Stack direction="horizontal" className="float-end" gap={3}>
              {isAddingShipments && (
                <div className="float-end">
                  <Spinner
                    animation="border"
                    variant="primary"
                    size="sm"
                    style={{ marginRight: '4px' }}
                  />
                </div>
              )}
              <Dropdown
                id={`${page}-addShipment__btn`}
                className="float-end"
              >
                <Dropdown.Toggle variant="light" className="bg-white shadow-sm" data-cy="addShipment_btn">
                  <Plus className="feather align-middle mt-n1" /> {JOB}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    id={`${page}-newShipment__btn`}
                    data-cy="newShipment_btn"
                    onClick={handleAddShipment}>
                    {NEW_SHIPMENT_OPTION}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Stack>
          </Permission>
        </Col>
      </Row>
      <Row className="shipment-card-container" data-cy="shipment-card-container">
        {isReqs && reqs.map((req) => {
          const bol = req.data.bol || 'No B.O.L';
          const po = req.data.job_request?.job?.poNumber || '';
          return (
            <Col md={4} key={req.entity_id}>
              <Card>
                <Card.Body>
                  <p>{`B.O.L: ${bol}`}</p>
                  <p>{`PO#: ${po}`}</p>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
        {!isReqs && (
          <p>{NO_SHIPMENTS}</p>
        )}
      </Row>
    </div>
  )
};
