/* eslint-disable */
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Table, Form } from "react-bootstrap"
import { useVirtualizer } from "@tanstack/react-virtual"
import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable } from "@tanstack/react-table"
import { ShipmentListView } from "../../../redux/models/shipment.models"
import { TableHeader } from "../ShipmentsTable/models/models"
import { InvoiceWritePermissions, UserReadPermissions } from "../../../components/shared/permissions/permissions.utils"
import Permission from "../../../components/shared/permissions/Permission"
import AssigneeTableRow from "../ShipmentsTable/AssigneeTableRow"
import { renderShipmentStatus } from "../ShipmentsTable/ShipmentsTableRow"
import { isPermissions } from "../../../redux/slices/settings.utils"
import { getPermissions } from "../../../config"

const columnHelper = createColumnHelper<ShipmentListView>()
                
const createColumns = (
  headers: TableHeader[],
  orgCode: string,
  permissions: string[],
  selectedShipments: string[],
  selectShipment: (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => void,
) => {
  const isInvoiceEnabled = isPermissions(orgCode, permissions, InvoiceWritePermissions);
  const isUserEnabled = isPermissions(orgCode, permissions, UserReadPermissions);
  const items: ColumnDef<ShipmentListView, string>[] = [];
  if (isInvoiceEnabled) {
    items.push(columnHelper.accessor("id", {
      cell: (info) => {
        return (
          <Permission resources={InvoiceWritePermissions}>
            <td
              role="gridcell"
              className="invoice-checkbox-col"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Form.Group data-cy={`multi_invoices_checkbox_${info.row.index}`}>
                <Form.Check
                  checked={selectedShipments?.includes(info.row.original.id)}
                  className="invoices-checkbox"
                  type="checkbox"
                  onChange={(e) => selectShipment(e, info.row.original.id)}
                />
              </Form.Group>
            </td>
          </Permission>
        )
      },
      header: () => <span>Selected</span>,
      size: 40,
    }))
  }
  const cols = headers
    .filter((header) => header.visible)
    .map((header) => {
      if (header.key === 'start_date') {
        return columnHelper.accessor("start_date", {
          cell: (info) => info.getValue(),
          header: () => <span>{`${header.name}`}</span>,
        })
      }
      if (header.key === 'customer') {
        return columnHelper.accessor("customer", {
          cell: (info) => info.getValue(),
          header: () => <span>{`${header.name}`}</span>,
        })
      }
      if (header.key === 'billing_type') {
        return columnHelper.accessor("billing_type", {
          cell: (info) => info.getValue(),
          header: () => <span>{`${header.name}`}</span>,
        })
      }
      if (header.key === 'container_no') {
        return columnHelper.accessor("container_no", {
          cell: (info) => info.getValue(),
          header: () => <span>{`${header.name}`}</span>,
        })
      }
      if (header.key === 'purchase_order_no') {
        return columnHelper.accessor("purchase_order_no", {
          cell: (info) => info.getValue(),
          header: () => <span>{`${header.name}`}</span>,
        })
      }
      if (header.key === 'status') {
        return columnHelper.accessor("status", {
          cell: (info) => {
            const val = info.getValue() || '';
            return renderShipmentStatus(val);
          },
          header: () => <span>{`${header.name}`}</span>,
        })
      }
      return columnHelper.accessor("shipment_no", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    });
  
  const newItems = [...items, ...cols];
  const assignee = columnHelper.accessor("assignee_id", {
    cell: (info) => {
      return (
        <Permission resources={UserReadPermissions}>
          <AssigneeTableRow userId={info.row.original.assignee_id || ''} />
        </Permission>
      )
    },
    header: () => <span>Assignee</span>,
  })
  return isUserEnabled ? [...newItems, assignee] : newItems;
}

const renderRow = (row: Row<ShipmentListView>) => {
  const cells = row
    ? row
        .getVisibleCells()
        .map((cell) => <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>)
    : null;
  return cells;
}

interface Props {
  headers: TableHeader[];
  shipments: ShipmentListView[];
  selectedShipments: string[];
  selectShipment: (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => void;
}


export default function ShipmentDataList({ headers, shipments, selectedShipments, selectShipment }: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const permissions = getPermissions();
  const flatData = shipments;
  const totalRows = flatData.length;
  const columns = createColumns(headers, orgCode || '', permissions, selectedShipments, selectShipment);
  const table = useReactTable({
    data: flatData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  const handleOpen = (shipment: ShipmentListView) => {
    navigate(`/${orgCode}/shipments/${shipment.id}`);
  };
  const parentRef = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: totalRows,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  })
  // <thead style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }}>
  return (
    <div ref={parentRef}>
      <Table striped bordered hover data-cy="shipments-table">
        <thead >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = table.getRowModel().rows[virtualRow.index]
            return (
              <tr
                key={virtualRow.index}
                onClick={() => handleOpen(row.original)}
                data-cy="shipments-table-row"
                // style={{
                //   height: `${virtualRow.size}px`,
                //   transform: `translateY(${virtualRow.start}px)`,
                // }}
              >
                {renderRow(row)}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

