export const MOVES_TABLE_HEADERS = [
  {
    name: 'Move',
    key: 'move',
  },
  {
    name: 'Route',
    key: 'destination',
  },
  {
    name: 'Action',
    key: 'action',
  },
  {
    name: 'Trailer',
    key: 'trailer',
  },
  {
    name: 'Arrival',
    key: 'scheduled_arrival_time',
  },
  {
    name: 'Shipment',
    key: 'shipment',
  },
  {
    name: 'Timeliness',
    key: 'timeliness',
  },
  {
    name: '',
    key: 'delete',
  },
];

export const ACTION_DROPDOWN_OPTIONS = [
  {
    value: 'bobtail',
    label: 'Bobtail',
  },
  {
    value: 'deliver',
    label: 'Deliver',
  },
  {
    value: 'drop',
    label: 'Drop',
  },
  {
    value: 'dump',
    label: 'Dump',
  },
  {
    value: 'empty',
    label: 'Empty',
  },
  {
    value: 'export',
    label: 'Export',
  },
  {
    value: 'finish',
    label: 'Finish',
  },
  {
    value: 'hook-empty',
    label: 'Hook Empty',
  },
  {
    value: 'hook',
    label: 'Hook Loaded',
  },
  {
    value: 'import',
    label: 'Import',
  },
  {
    value: 'load',
    label: 'Load',
  },
  {
    value: 'lunch',
    label: 'Lunch',
  },
  {
    value: 'pick',
    label: 'Pick',
  },
  {
    value: 'return',
    label: 'Return',
  },
  {
    value: 'start',
    label: 'Start',
  },
  {
    value: 'tip',
    label: 'Tip',
  },
];
