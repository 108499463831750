import { Fleet } from "../../redux/models/settings.models";
import { Shipment } from "../../redux/models/shipment.models";
import { CreateReservationRequest, Job } from "../../redux/slices/seaspan/seaspan.models";
import { Move, TripDetails } from "../../redux/models/trip.models";
import { DateService } from "../../utils/dateService";

export const getDefaultTrailer = (fleet: Record<string, Fleet>) => {
  const trailers = Object.values(fleet);
  if (trailers.length > 0) return trailers[0];
  return null;
};

export const getDefaultShipment = (shipments: Shipment[]) => {
  if (shipments.length > 0) return shipments[0];
  return null;
};

export const mapTrailerToRes = (res: CreateReservationRequest, trailer: Fleet): CreateReservationRequest | null => {
  const request = res.job_request;
  const job = res.job_request?.job;
  if (!request || !job) return null;
  return {
    ...res,
    job_request: {
      ...request,
      job: {
        ...job,
        unitID: trailer.data.name || '',
        unitType: trailer.data.seaspan_unit_type || '',
        licencePlate: trailer.data.license_plate || '',
        length: trailer.data.length || 0,
        overLengthFront: trailer.data.over_length_front || 0,
        overLengthBack: trailer.data.over_length_back || 0,
        width: trailer.data.width || 0,
        genset: trailer.data.genset || false,
      }
    }
  }
};

export const mapShipmentsToRes = (
  res: CreateReservationRequest, shipments: Shipment[]
): CreateReservationRequest | null => {
  const request = res.job_request;
  const job = res.job_request?.job;
  if (!request || !job) return null;
  const temps = shipments.map((ship) => ship.data.temp).sort();
  const dg = shipments.find((ship) => ship.data.dangerous_goods)?.data.dangerous_goods || false;
  const isTemps = temps.length > 0;
  return {
    ...res,
    job_request: {
      ...request,
      job: {
        ...job,
        maxTemp: isTemps ? temps[0] : 0,
        movementMode: "FUL",
        hazardous: dg,
      }
    }
  }
};

const mapJobData = (job: Job) => {
  const data = {
    ...job,
    genset: `${job.genset}`,
    dnp: `${job.dnp}`,
    hazardous: `${job.hazardous}`,
    mixedHazards: `${job.mixedHazards}`,
    preferredPickupDateTime: DateService.seaspan(job.preferredPickupDateTime),
  };
  return data;
}

export const mapResToPostBody = (res: CreateReservationRequest) => {
  const request = res.job_request;
  const job = request?.job;
  if (!request || !job) return null;
  const jobData = mapJobData(job);
  const now = DateService.seaspan(new Date());
  console.log(now);
  return {
    "trip_id": res.trip_id || '',
    "trailer_id": res.trailer_id || "",
    "shipment_ids": res.shipment_ids || [],
    "job_request": {
        "messageDateTime": DateService.seaspan(new Date()),
        "job": {
          ...jobData
        }
    }
  }
};

export const getDefaultReservation = (
  trip: TripDetails | null,
  trailer: Fleet | null,
  shipment: Shipment | null
): CreateReservationRequest => {
  let data: CreateReservationRequest = {
    trip_id: trip?.entity_id,
    trailer_id: trailer?.entity_id,
    shipment_ids: shipment ? [shipment.entity_id]: undefined,
    job_request: {
      messageDateTime: '',
      job: {
        action: 'INSERT',
        customerShortName: 'AQUAT',
        poNumber: '',
        jobType: 'TRL',
        route: "Tilbury > Swartz Bay",
        preferredPickupDateTime: DateService.seaspanHHmm(new Date()),
        unitID: trailer?.data.name || '',
        unitType: trailer?.data.seaspan_unit_type || 'VAN',
        length: trailer?.data.length || 0,
        width: trailer?.data.width || 0,
        genset: trailer?.data.genset || false,
        maxTemp: shipment?.data.temp || 0,
        movementMode: "FUL",
        hazardous: shipment?.data.dangerous_goods || false,
        dnp: false,
        mixedHazards: false,
        liveDrivers: 0
      }
    }
  };

  if (trailer) {
    const trailerData = mapTrailerToRes(data, trailer);
    if (trailerData) {
      data = trailerData;
    }
  }

  if (shipment) {
    const shipmentData = mapShipmentsToRes(data, [shipment]);
    if (shipmentData) {
      data = shipmentData;
    }
  }

  return data;
};

export const createTripTrailers = (moves: Move[], fleet: Record<string, Fleet>) => {
  return moves.reduce((store: Record<string, Fleet>, move) => {
    const trailerIds = move.data.trailer_ids || [];
    const trContainer = trailerIds.reduce((st: Record<string, Fleet>, trailerId) => {
      const fl = fleet[trailerId];
      return {
        ...st,
        [trailerId]: fl,
      }
    }, {});
    return {
      ...store,
      ...trContainer,
    }
  }, {});
}

export const createReservation = (trip: TripDetails | null, fleet: Record<string, Fleet>) => {
  const moves = trip?.data.moves || [];
  const shipments = trip?.data.shipments || [];
  const trailers = createTripTrailers(moves, fleet);
  const defaultTrailer = getDefaultTrailer(trailers);
  const defaultShipment = getDefaultShipment(shipments);
  return getDefaultReservation(trip, defaultTrailer, defaultShipment);
};
