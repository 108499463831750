/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  updateInvoiceDetails, setAreInvoiceDetailsLoading, updateInvoice, sendInvoice
} from '../../../redux/slices/invoices';
import InvoiceForm from './InvoiceForm';
import LineItemsTable from '../InvoicesTable/LineItemsTable';
import { formatCurrency } from '../utils/invoice.utils';
import InvoiceShipments from './InvoiceShipments';
import { Invoice } from '../../../redux/models/invoice.models';
import Analytics from '../../../utils/analytics';
import { isPermissions } from '../../../redux/slices/settings.utils';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import Permission from '../../../components/shared/permissions/Permission';
import { getPermissions } from '../../../config';
import { EntityContainer } from '../../../redux/models/core.models';
import InvoiceFooterButtons from '../../../components/shared/InvoiceFooterBtns';
import EdiNotification from './components/EdiNotification';
import { errorToast, infoToast, successToast } from '../../../components/notifications/app-toast';
import { Api } from '../../../services/services';
import { NotificationPreference } from '../../../redux/models/settings.models';
import ConfirmModal from '../../../components/modals/ConfirmModal';

const page = 'invoiceDetails';
const WriteInvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

const ReadShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Read,
};

const getConfirmTitle = (preferences: NotificationPreference | null) => {
  const isEdi = preferences?.data.edi || false;
  return isEdi ? 'Confirm EDI Transfer' : 'Confirm Send Invoice';
}

const getConfirmMessage = (preferences: NotificationPreference | null) => {
  const isEdi = preferences?.data.edi || false;
  if (isEdi) return `Are you sure you'd like to create an EDI transfer for this invoice?`;
  return `Are you sure you'd like to send this invoice?`;
}

export default function InvoiceDetails({ invoiceDetails }: { invoiceDetails: Invoice }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [preferences, setPreferences] = useState<NotificationPreference | null>(null);
  const { orgCode } = useParams();
  const permissions = getPermissions();

  const isEditable = isPermissions(orgCode, permissions, WriteInvoicePermissions);
  const {
    bill_to: billTo, customer, total, sub_total: subTotal, line_items: lineItems, shipments, currency,
  } = invoiceDetails.data;
  const customerName = customer.data.name;
  const billToClient = billTo.data.name;

  const displayCustomerName = `Customer: ${customerName || ''}`;
  const displayBillTo = `Bill To: ${billToClient || ''}`;
  const displaySubtotal = `${formatCurrency(currency, subTotal) || ''}`;
  const displayTotal = `${formatCurrency(currency, total) || ''} ${currency}`;
  const defss: EntityContainer<{ name: string; amount: number; sub_total: number; }> = {};
  const taxes = lineItems.reduce((store, item) => {
    const updates = {
      ...store
    };
    const taxId = item.data.tax_id || '';
    const itemAmount = item.data.tax_amount || 0;
    const itemName = item.data.tax_name || '';
    const itemRate = item.data.tax_rate || 0;
    const itemSubtotal = item.data.sub_total || 0;
    const existingAmount = store[taxId]?.amount || 0;
    const existingSubtotal = store[taxId]?.sub_total || 0;
    if (taxId) {
      updates[taxId] = {
        'name': `${itemName} @ ${itemRate * 100}%`,
        'sub_total': existingSubtotal + itemSubtotal,
        'amount': existingAmount + itemAmount,
      }
    }
    return updates;
  }, defss);

  const handleCancel = () => navigate(-1);

  const handleSave = async () => {
    dispatch(setAreInvoiceDetailsLoading(true));
    try {
      setIsSaving(true);
      await updateInvoice(invoiceDetails);
      const successMessage = 'Invoice was updated successfully.';
      successToast(successMessage);
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't update invoice. ${e.message}. Please contact support if the problem persists.`;
        errorToast(errorMessage);
      }
      Analytics.capture(e);
    } finally {
      setIsSaving(false);
      dispatch(setAreInvoiceDetailsLoading(false));
    }
  };
  const confirmSaveSend = async () => {
    const contactId = invoiceDetails.data.bill_to?.entity_id;
    if (!contactId) {
      errorToast('No bill to configured');
      return;
    }
    try {
      setIsSaving(true);
      // load preferences
      const response = await Api.NotificationPreferences.getPreferencesByContactId(contactId)
      if (response.status === 200) {
        console.log(response.data.data);
        await setPreferences(response.data.data);
        setShowConfirm(true);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveSend = async () => {
    dispatch(setAreInvoiceDetailsLoading(true));
    try {
      setIsSending(true);
      const response = await sendInvoice(invoiceDetails);
      if (response.status === 200) {
        const successMessage = 'Invoice was queued for EDI Transfer.';
        infoToast(successMessage);
        navigate(-1);
      }
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't send invoice. ${e.message}. Please contact support if the problem persists.`;
        errorToast(errorMessage);
      }
      Analytics.capture(e);
    } finally {
      setIsSending(false);
      dispatch(setAreInvoiceDetailsLoading(false));
    }
  };
  const handleForm = (prop: string, value: any) => {
    const updates = {
      ...invoiceDetails,
      data: {
        ...invoiceDetails.data,
        [prop]: value,
      },
    };
    dispatch(updateInvoiceDetails(updates));
  };

  return (
    <>
      <Card>
        <Card.Body className="invoice-header">
          <Row className="mt-4">
            <Col sm={8} className={`${page}-billing-info`}>
              <h3>{displayCustomerName}</h3>
              <h3>{displayBillTo}</h3>
              {invoiceDetails && (
                <EdiNotification invoice={invoiceDetails} />
              )}
            </Col>
            <InvoiceForm invoiceDetails={invoiceDetails} handleForm={handleForm} isEditable={isEditable} page={page} />
          </Row>
        </Card.Body>
        <Card.Body className="invoice-lineItems">
          <LineItemsTable lineItems={lineItems} shipments={shipments} page={page} currency={currency} />
          <div className="flex align-items-end">
            <div />
            <div className="float-end">
              <Table style={{ borderBottom: 'none' }}>
                <tbody>
                  <tr style={{ userSelect: 'none' }}>
                    <td><h4>Subtotal:</h4></td>
                    <td><h4>{displaySubtotal}</h4></td>
                  </tr>
                  {Object.values(taxes).map((tax) => {
                    return (
                      <tr key={tax.name} style={{ userSelect: 'none' }}>
                        <td>{`${tax.name} on ${formatCurrency(currency, tax.sub_total) || ''}`}</td>
                        <td>{`${formatCurrency(currency, tax.amount) || ''}`} </td>
                      </tr>
                    )
                  })}
                  <tr>
                    <td><h3>Total:</h3></td>
                    <td><h3>{displayTotal}</h3></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Permission resources={ReadShipmentPermissions}>
        <InvoiceShipments page={page} shipments={shipments} isEditable={isEditable} />
      </Permission>
      {showConfirm && (
        <ConfirmModal
          title={getConfirmTitle(preferences)}
          details={getConfirmMessage(preferences)}
          btnTitle="Send"
          saveDisabled={isSending}
          shouldShowModal={showConfirm}
          isSaving={isSending}
          handleSave={handleSaveSend}
          cancelSave={() => setShowConfirm(false)}
        />
      )}
      <InvoiceFooterButtons
        handleSave={handleSave}
        handleSaveSend={confirmSaveSend}
        handleClose={handleCancel}
        page={page}
        isSaving={isSaving}
        deleteButton={false}
        disabled={false}
        isEditable={isEditable}
      />
    </>
  );
}
