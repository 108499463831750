import { Badge } from 'react-bootstrap';
import { Invoice } from '../../../../redux/models/invoice.models';

const getVariant = (status: string) => {
  if (status === 'queued') return 'info';
  if (status === 'success') return 'success';
  if (status === 'error') return 'danger';
  return 'primary';
}

export default function EdiNotification({ invoice }: { invoice: Invoice }) {
  const status = invoice.data.edi_notification_status;
  if (!invoice || !status) return null;
  return (
    <div>
      <h5>Edi Transfer</h5>
      <Badge bg={getVariant(status)} className="capitalized">{status}</Badge>
    </div>
  );
}
