/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import { CreateReservationRequest, Job, Hazard } from "../../../redux/slices/seaspan/seaspan.models";
import { getDefaultReservation, mapResToPostBody } from '../seaspan-job.utils';
import { Api } from "../../../services/services";
import ButtonSpinner from "../../../components/shared/ButtonSpinner";
import { errorToast, successToast } from "../../../components/notifications/app-toast";

const defaultRes = getDefaultReservation(null, null, null);


const validationSchema = Yup.object().shape({
  preferredPickupDateTime: Yup.string().required("Pickup Date/Time is required"),
  unitID: Yup.string().required("Unit ID is required"),
  unitType: Yup.string().required("Unit Type is required"),
  movementMode: Yup.string().required("Trailer Contents must be set to empty or full"),
  length: Yup.number().min(1, "Length must be at least 1m").required("Length is required"),
  width: Yup.number().min(1, "Width must be at least 1m").required("Width is required"),  
  maxTemp: Yup.number()
    .min(-50, "Temperature too low")
    .when("genset", {
      is: true,
      then: (schema) => schema.required("Max Temp is required when Genset is on"),
    }),
});

interface Props {
  reservation: CreateReservationRequest;
  tripId: string;
  trailerId: string;
  shipmentIds: string[];
  onSave: () => void;
}

function JobForm({ tripId, trailerId, shipmentIds, reservation, onSave }: Props) {
  const [submittedData, setSubmittedData] = useState<Job | null>(null);
  const [formikKey, setFormikKey] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const defaultData: any = reservation.job_request?.job || defaultRes;
  console.log(defaultData);
  const getPostBody = (job: Job) => {
    if (!job) return null;
    const data: CreateReservationRequest = {
      ...reservation,
      trip_id: tripId,
      trailer_id: trailerId,
      shipment_ids: shipmentIds,
      job_request: {
        messageDateTime: new Date().toISOString(),
        ...reservation.job_request,
        job: {
          ...job,
          length: Math.round(job.length),
          width: Math.round(job.width),
          overLengthFront: job.overLengthFront !== undefined && job.overLengthFront !== null ? Math.round(job.overLengthFront) : undefined,
          overLengthBack: job.overLengthBack !== undefined && job.overLengthBack !== null ? Math.round(job.overLengthBack) : undefined,
        }
      }
    }
    return mapResToPostBody(data);
  };
  const handleSubmitJob = async (values: Job) => {
    console.log('handleSubmitJob');
    const data = getPostBody(values);
    console.log(data);
    if (!data || !tripId) return;
    if (!trailerId) {
      errorToast("Must select a trailer")
      return;
    }
    try {
      setIsSaving(true);
      const response = await Api.Seaspan.createReservation(data as any);
      if (response.status === 200) {
        console.log(response.data.data);
        successToast('Job sent to TOPS. Seaspan will email with confirmation');
        onSave();
      }
    } catch (error) {
      console.log(error);
      errorToast("Couldn't send job to TOPS");
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    setFormikKey((prevKey) => prevKey + 1)
  }, [reservation]);
  return (
    <Formik
      key={formikKey}
      initialValues={defaultData}
      validationSchema={validationSchema}
      onSubmit={(values: Job) => {
        console.log(values);
        setSubmittedData(values);
        handleSubmitJob(values);
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="preferredPickupDateTime">
                <Form.Label>Preferred Pickup Date/Time</Form.Label>
                <Field type="datetime-local" name="preferredPickupDateTime" className="form-control" />
                <ErrorMessage name="preferredPickupDateTime" component="div" className="text-danger" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="customerShortName">
                <Form.Label>Account</Form.Label>
                <Field as="select" name="customerShortName" className="form-control">
                  <option value="">Select</option>
                  <option value="AQUAT">Aquatrans Distributors Incorporated</option>
                  <option value="AQUATSR">Aquatrans - Van Whole Produce</option>
                </Field>
                <ErrorMessage name="customerShortName" component="div" className="text-danger" />
              </Form.Group>
            </Col>
          </Row>

          {/* Unit Details */}
          <Row>
            <Col>
              <Form.Group controlId="unitID">
                <Form.Label>Unit ID</Form.Label>
                <Field name="unitID" className="form-control" />
                <ErrorMessage name="unitID" component="div" className="text-danger" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="unitType">
                <Form.Label>Unit Type</Form.Label>
                <Field as="select" name="unitType" className="form-control">
                  <option value="">Select</option>
                  <option value="VAN">VAN</option>
                  <option value="AUTO">AUTO</option>
                  <option value="BT">B-Train</option>
                  <option value="DU">Drop Unit</option>
                  <option value="FLAT">FLAT</option>
                  <option value="LU">LU</option>
                  <option value="TANK">TANK</option>
                </Field>
                <ErrorMessage name="unitType" component="div" className="text-danger" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="licencePlate">
                <Form.Label>Licence Plate</Form.Label>
                <Field name="licencePlate" className="form-control" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="movementMode">
                <Form.Label>Trailer Contents</Form.Label>
                <Field as="select" name="movementMode" className="form-control">
                  <option value="FUL">Full</option>
                  <option value="EMP">Empty</option>
                </Field>
                <ErrorMessage name="route" component="div" className="text-danger" />
              </Form.Group>
            </Col>
          </Row>

          {/* Route */}
          <Form.Group controlId="route">
            <Form.Label>Route</Form.Label>
            <Field as="select" name="route" className="form-control">
              <option value="Tilbury > Swartz Bay">{"Tilbury > Swartz Bay"}</option>
              <option value="Swartz Bay > Tilbury">{"Swartz Bay > Tilbury"}</option>
              <option value="Surrey > Duke Point">{"Surrey > Duke Point"}</option>
              <option value="Duke Point > Surrey">{"Duke Point > Surrey"}</option>
              <option value="Duke Point > Tilbury">{"Duke Point > Tilbury"}</option>
              <option value="Tilbury > Duke Point">{"Tilbury > Duke Point"}</option>
            </Field>
            <ErrorMessage name="route" component="div" className="text-danger" />
          </Form.Group>

          {/* Dimensions */}
          <Row className="mt-2">
            <Col>
              <Form.Group controlId="length">
                <Form.Label>Length (ft)</Form.Label>
                <Field type="number" name="length" className="form-control" step="1" min="0" />
                <ErrorMessage name="length" component="div" className="text-danger" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="width">
                <Form.Label>Width (ft)</Form.Label>
                <Field type="number" name="width" step="1" min="0" className="form-control" />
                <ErrorMessage name="width" component="div" className="text-danger" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="overLengthFront">
                <Form.Label>Over Length Front (ft)</Form.Label>
                <Field type="number" step="1" min="0" name="overLengthFront" className="form-control" />
                <ErrorMessage name="overLengthFront" component="div" className="text-danger" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="overLengthBack">
                <Form.Label>Over Length Back (ft)</Form.Label>
                <Field type="number" step="1" min="0" name="overLengthBack" className="form-control" />
                <ErrorMessage name="overLengthBack" component="div" className="text-danger" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Form.Group controlId="customerComments">
                <Form.Label>Requested Sailing Time & Other Notes</Form.Label>
                <Field name="customerComments" className="form-control" />
              </Form.Group>
            </Col>
          </Row>

          {/* Genset (Reefer) */}
          <Form.Group controlId="genset" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Reefer On (Genset)"
              checked={values.genset}
              onChange={(e) => setFieldValue("genset", e.target.checked)}
            />
          </Form.Group>

          {values.genset && (
            <Form.Group controlId="maxTemp">
              <Form.Label>Reefer Temperature (°F) Set to lowest value required</Form.Label>
              <Field type="number" name="maxTemp" className="form-control" />
              <ErrorMessage name="maxTemp" component="div" className="text-danger" />
            </Form.Group>
          )}

          <Form.Group controlId="dnp" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Do Not Pack"
              checked={values.dnp}
              onChange={(e) => setFieldValue("dnp", e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="hazardous" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Hazardous"
              checked={values.hazardous}
              onChange={(e) => setFieldValue("hazardous", e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="mixedHazards" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Mixed Hazards"
              checked={values.mixedHazards}
              onChange={(e) => setFieldValue("mixedHazards", e.target.checked)}
            />
          </Form.Group>

          {/* Hazards Section - Only shown when hazardous is true */}
          {values.hazardous && (
            <Row className="mt-3">
              <h5>Hazardous Materials</h5>
              <div>
                <FieldArray name="hazards">
                  {({ remove, push }) => (
                    <div>
                      {values.hazards && values.hazards.length > 0 ? (
                        values.hazards.map((hazard: Hazard, index: number) => (
                          <Row key={index} className="mb-3 align-items-end">
                            <Col>
                              <Form.Group>
                                <Form.Label>UN Number</Form.Label>
                                <Field
                                  name={`hazards.${index}.unNumber`}
                                  className="form-control"
                                  placeholder="e.g. UN1203"
                                />
                                <ErrorMessage
                                  name={`hazards.${index}.unNumber`}
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>IMO Class</Form.Label>
                                <Field
                                  name={`hazards.${index}.imoClass`}
                                  className="form-control"
                                  placeholder="e.g. 3"
                                />
                                <ErrorMessage
                                  name={`hazards.${index}.imoClass`}
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Proper Shipping Name</Form.Label>
                                <Field
                                  name={`hazards.${index}.properShippingName`}
                                  className="form-control"
                                  placeholder="e.g. Gasoline"
                                />
                                <ErrorMessage
                                  name={`hazards.${index}.properShippingName`}
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Button variant="danger" onClick={() => remove(index)} size="sm">
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <Alert variant="info">
                          No hazards added yet. Click to add hazardous materials information.
                        </Alert>
                      )}
                      <Button
                        variant="outline-primary"
                        onClick={() => push({ unNumber: "", imoClass: "", properShippingName: "" })}
                      >
                        Add Hazard
                      </Button>
                      <ErrorMessage name="hazards" component="div" className="text-danger mt-2" />
                    </div>
                  )}
                </FieldArray>
              </div>
            </Row>
          )}

          {/* Submit Button */}
          <Button variant="primary" type="submit" className="mt-3">
            {isSaving ? <ButtonSpinner variant="light" /> : 'Submit'}
          </Button>

          {/* Show Submitted Data */}
          {submittedData && (
            <Alert variant="success" className="mt-3">
              <pre>{JSON.stringify(getPostBody(submittedData), null, 2)}</pre>
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default JobForm;
