/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { BillingItemData, BillingTypeData } from '../redux/models/billing.models';
import { BoardData } from '../redux/models/board.models';
import { ContactData } from '../redux/models/contact.models';
import {
  NoteTemplateData,
  TripTemplateData,
  ShipmentTypeData,
  ShippingLineData,
  GroupData,
  VehicleData,
  DriverData,
  FuelSurchargeTypeData,
  UomData,
  GlCodeData,
  DepartmentCodeData,
  CostData,
  FleetData,
} from '../redux/models/settings.models';
import { MoveData } from '../redux/models/trip.models';
import axiosInstance from '../utils/axios';
import axiosHopperInstance from '../utils/hopperAxios';
import AssetLocationApi from './asset-location/AssetLocationApi';
import AddressServiceProvider from './base/AddressServiceProvider';
import { DashboardServiceProvider } from './base/DashboardServiceProvider';
import { EntityServiceProvider } from './base/EntityServiceProvider';
import InvoiceServiceProvider from './base/InvoiceServiceProvider';
import { SettingServiceProvider } from './base/SettingServiceProvider';
import ShipmentServiceProvider from './base/ShipmentServiceProvider';
import TripServiceProvider from './base/TripServiceProvider';
import DriverAvailabilityServiceProvider from './driver-availability/DriverAvailabilityServiceProvider';
import IntegrationApiProvider from './hopper/IntegrationApiProvider';
import { GoogleMapServiceProvider } from './maps/GoogleMapServiceProvider';
import NotificationPreferenceApi from './notification-preferences/NotificationPreferenceApi';
import { NotificationPollProvider } from './notifications/NotificationPollProvider';
import SeaspanApi from './seaspan/SeaspanApi';

export const BoardApi = new SettingServiceProvider<BoardData>('board', axiosInstance);
export const DriverApi = new SettingServiceProvider<DriverData>('driver', axiosInstance);
export const GroupApi = new SettingServiceProvider<GroupData>('group', axiosInstance);
export const NotesTemplateApi = new EntityServiceProvider<NoteTemplateData>('note-template', axiosInstance);
export const TripTemplateApi = new EntityServiceProvider<TripTemplateData>('trip-template', axiosInstance);
export const ShipmentApi = new ShipmentServiceProvider('shipment', axiosInstance);
export const ShipmentTypeApi = new SettingServiceProvider<ShipmentTypeData>('shipment-type', axiosInstance);
export const ShippingLineApi = new SettingServiceProvider<ShippingLineData>('shipping-line', axiosInstance);

export class Api {
  static Addresses = new AddressServiceProvider('address', axiosInstance);

  static AssetLocation = AssetLocationApi;
  
  static Availability = new DriverAvailabilityServiceProvider('driver-availability', axiosInstance)

  static BillingItems = new SettingServiceProvider<BillingItemData>('billing-item', axiosInstance);

  static BillingTypes = new SettingServiceProvider<BillingTypeData>('billing-type', axiosInstance);
  
  static Boards = new SettingServiceProvider<BoardData>('board', axiosInstance);

  static Contacts = new SettingServiceProvider<ContactData>('contact', axiosInstance);

  static Costs = new SettingServiceProvider<CostData>('cost', axiosInstance);

  static Dashboard = new DashboardServiceProvider(axiosInstance);

  static DepartmentCodes = new SettingServiceProvider<DepartmentCodeData>('department-code', axiosInstance);

  static Drivers = new SettingServiceProvider<DriverData>('driver', axiosInstance);

  static Fleet = new SettingServiceProvider<FleetData>('fleet', axiosInstance);

  static FuelSurchargeTypes = new SettingServiceProvider<FuelSurchargeTypeData>('fuel-surcharge-type', axiosInstance);

  static GlCodes = new SettingServiceProvider<GlCodeData>('gl-code', axiosInstance);

  static Groups = new SettingServiceProvider<GroupData>('group', axiosInstance);

  static Integrations = new IntegrationApiProvider();

  static Invoices = new InvoiceServiceProvider('invoice', axiosInstance);

  static Maps = new GoogleMapServiceProvider();

  static Moves = new EntityServiceProvider<MoveData>('move', axiosInstance);

  static NotesTemplates = new EntityServiceProvider<NoteTemplateData>('note-template', axiosInstance);

  static NotificationPoll = new NotificationPollProvider();

  static NotificationPreferences = NotificationPreferenceApi;

  static Organisation = new EntityServiceProvider<any>('organization', axiosHopperInstance);

  static TripTemplates = new EntityServiceProvider<TripTemplateData>('trip-template', axiosInstance);

  static Seaspan = SeaspanApi;

  static Shipments = new ShipmentServiceProvider('shipment', axiosInstance);

  static ShipmentTypes = new SettingServiceProvider<ShipmentTypeData>('shipment-type', axiosInstance);

  static ShippingLines = new SettingServiceProvider<ShippingLineData>('shipping-line', axiosInstance);

  static Trips = new TripServiceProvider('trip', axiosInstance);

  static Uom = new SettingServiceProvider<UomData>('uom', axiosInstance);

  static Vehicles = new EntityServiceProvider<VehicleData>('vehicle', axiosInstance);
}