/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { selectDepartmentCodeObject, selectUomObject } from '../../../../redux/slices/settings';
import { ControlInput } from '../../../../components/shared/Input';
import Permission from '../../../../components/shared/permissions/Permission';
import { InvoiceWritePermissions } from '../../../../components/shared/permissions/permissions.utils';

interface Props {
  invoiceItem: any;
  index?: number;
  disabled: boolean;
  handleChange: (entityId: string) => void;
  onDelete?: (index: number) => void;
}

function InvoiceRow({
  invoiceItem,
  index,
  disabled = false,
  handleChange,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const uoms = useSelector(selectUomObject);
  const depCodes = useSelector(selectDepartmentCodeObject);
  const {
    description,
    department_code_id: depCodeId,
    uom_id: uomId,
    billing_item_id: entityId,
  } = invoiceItem;
  const depCode = depCodeId ? depCodes[depCodeId] : null;
  const depCodeName = depCode?.data?.name || '';
  const uom = uomId ? uoms[uomId] : null;
  const uomName = uom?.data?.name || '';
  const isDelete = index !== undefined && onDelete !== undefined;

  const handleDelete = () => {
    if (isDelete) onDelete(index);
  };
  return (
    <Row className="mt-2">
      <Col md={3}>{description || ''}</Col>
      <Col md={2}>
        <ControlInput
          labelText=""
          name={entityId}
          page="0"
          type="number"
          value={invoiceItem.quantity}
          shouldHaveMargin={false}
          dataCy={`invoiceItem_${description}`}
          handleChange={handleChange(entityId)}
          disabled={disabled}
        />
      </Col>
      <Col md={1}>{uomName}</Col>
      <Col md={2}>{depCodeName}</Col>
      {isDelete && (
        <Col md={2}>
          <Permission resources={InvoiceWritePermissions}>
            <Button variant="outline-danger" onClick={handleDelete}>{`${t('Delete')}`}</Button>
          </Permission>
        </Col>
      )}
    </Row>
  );
}

InvoiceRow.defaultProps = {
  index: undefined,
  onDelete: undefined,
};

export default InvoiceRow;