import React from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';


export default function LoadTenderDetails() {
  const { loadTenderId } = useParams();
  return (
    <Row>
      <Col md={8}>
        <h3>{`Load tender ${loadTenderId || ''}`}</h3>
      </Col>
      <Col md={4}>
        <Stack gap={4}>
          <h4>Workflows</h4>
          <Stack direction="horizontal" gap={2}>
            <Button type="submit">Approve</Button>
            <Button type="submit" variant="danger">Reject</Button>
          </Stack>
          <Button type="submit" variant="secondary" disabled>Create shipment</Button>
          <Button type="submit" variant="secondary" disabled>Create trip</Button>
          <Button type="submit" variant="secondary" disabled>Create Seaspan Job</Button>
          <Button type="submit" variant="secondary" disabled>Create invoice</Button>
        </Stack>
      </Col>
    </Row>
  );
}
