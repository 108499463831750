import { Link, useParams } from "react-router-dom"
import { Col, Card } from "react-bootstrap"
import { Address } from "../../../redux/models/settings.models"

interface Props {
  address: Address;
}

export default function AssetTrackerCol({ address }: Props) {
  const { orgCode, boardId } = useParams();
  return (
    <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
      <Card className="mb-3">
        <Card.Body>
          <Card.Title className="h6 mb-0">
            <Link to={`/${orgCode}/b/assets/${boardId}/a/${address.entity_id}`}>
              <h3 className="h5 mb-3">{address.data.samsara_name || ''}</h3>
            </Link>
          </Card.Title>
        </Card.Body>
      </Card>
    </Col>
  )
}