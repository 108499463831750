/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap'
import { useVirtualizer } from "@tanstack/react-virtual"
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import ShipmentRow  from './rows/ShipmentRow'
import { TripTableColumns } from '../../../../redux/models/board.models';
import { ShipmentListView } from '../../../../redux/models/shipment.models';
import AddShipmentRow from './rows/AddShipmentRow';
import { renderShipmentStatus } from '../../ShipmentsTable/ShipmentsTableRow';


const columnHelper = createColumnHelper<ShipmentListView>()

const createColumns = (headers: TripTableColumns[]) => {
  return headers.filter((header) => {
    if (header.key === 'expand') return false;
    if (header.key === 'action') return false;
    return header.visible
  }).map((header) => {
    if (header.key === 'start_date') {
      return columnHelper.accessor("start_date", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'customer') {
      return columnHelper.accessor("customer", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'billing_type') {
      return columnHelper.accessor("billing_type", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'container_no') {
      return columnHelper.accessor("container_no", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'purchase_order_no') {
      return columnHelper.accessor("purchase_order_no", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'status') {
      return columnHelper.accessor("status", {
        cell: (info) => {
          const val = info.getValue() || '';
          return renderShipmentStatus(val);
        },
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'origin') {
      return columnHelper.accessor("origin", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'requested_pick_up_date') {
      return columnHelper.accessor("requested_pick_up_date", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'destination') {
      return columnHelper.accessor("destination", {
        cell: (info) => {
          const val = info.getValue() || '';
          return renderShipmentStatus(val);
        },
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'requested_drop_off_date') {
      return columnHelper.accessor("requested_drop_off_date", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    if (header.key === 'base_rate') {
      return columnHelper.accessor("base_rate", {
        cell: (info) => info.getValue(),
        header: () => <span>{`${header.name}`}</span>,
      })
    }
    return columnHelper.accessor("shipment_no", {
      cell: (info) => info.getValue(),
      header: () => <span>{`${header.name}`}</span>,
    })
  });
}

interface Props {
  columns: TripTableColumns[];
  startResizing: (index: number) => (e: React.MouseEvent) => void;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateShipment: () => void;
  onCreateTrip: (shipmentId: string) => void;
  selectedShipments: string[];
  shipments: ShipmentListView[];
}

export default function DataGridTable({
  columns, selectedShipments, shipments,
  onUpdate, onSelect, startResizing, onOpen, onInvoice, onCreateShipment, onCreateTrip
}: Props) {
  const tableRef = useRef<HTMLTableElement>(null);
  const flatData = shipments;
  const totalRows = flatData.length;
  const headers = createColumns(columns);
  const table = useReactTable({
    data: flatData,
    columns: headers,
    getCoreRowModel: getCoreRowModel(),
  });
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: totalRows,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });
  useEffect(() => {
    if (tableRef.current) {
      const tableWidth = columns.reduce((sum, col) => sum + col.width, 0)
      tableRef.current.style.width = `${tableWidth}px`
    }
  }, [columns])
  return (
    <div ref={parentRef} className="table-responsive">
      <Table ref={tableRef} bordered hover style={{ tableLayout: 'fixed' }}>
        <colgroup>
          {columns.map((column) => (
            <col key={column.key} style={{ width: column.width }} />
          ))}
        </colgroup>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{ 
                    position: 'relative',
                    padding: '0.75rem'
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = table.getRowModel().rows[virtualRow.index]
            const shipment = row.original;
            return shipment ? (
              <ShipmentRow
                key={shipment.id}
                shipment={shipment}
                onUpdate={onUpdate}
                onSelect={onSelect}
                selectedShipments={selectedShipments}
                columns={columns}
                onOpen={onOpen}
                onInvoice={onInvoice}
                onCreateTrip={onCreateTrip}
              />
            ) : null;
          })}
          <AddShipmentRow numCols={columns.length} onAdd={onCreateShipment} />
        </tbody>
      </Table>
    </div>
  );
}
