/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { Descendant } from 'slate';
import { SETTINGS_NAMES } from "../../constants/core.constants";
import { getSettingFromLocalStorage } from "../../utils/core.utils";
import { DataModel, EntityContainer, ExternalIds } from "./core.models";
import { Board } from './board.models';
import { ShipmentData } from './shipment.models';
import { TripData } from './trip.models';

export interface AddressData {
  external_ids?: ExternalIds;
  port: boolean;
  samsara_id: string;
  samsara_formatted_address?: string;
  samsara_name: string;
  samsara_latitude: number;
  samsara_longitude: number;
}

export type Address = DataModel<AddressData>;

export interface DepartmentCodeData {
  name: string;
  department_code: string;
}

export type DepartmentCode = DataModel<DepartmentCodeData>;

export interface UomData {
  name: string;
  symbol?: string;
  unit?: string;
}

export type UOM = DataModel<UomData>;

export interface CostData {
  name: string;
  rate: number;
  unit_id?: string;
  unit?: UOM | null;
}

export type Cost = DataModel<CostData>;

export interface DriverData {
  allow_overtime: boolean;
  air_cargo: boolean;
  availability?: string[];
  b_train: boolean;
  ct_pat: boolean;
  cost_id?: string;
  cost?: Cost | null;
  dangerous_goods: boolean;
  forklift_certified?: boolean;
  efficiency?: number | null;
  email: string | null;
  home_yard_id: string | null;
  home_yard?: Address | null;
  light_duties: boolean;
  max_overtime?: number | null;
  outside_cartage: boolean;
  hp_tag_ids: string[];
  tag_ids: string[];
  samsara_id?: string | null;
  samsara_name: string;
  samsara_phone?: string;
  samsara_username: string;
  tipper_truck: boolean;
  tier?: number | null;
}

export type Driver = DataModel<DriverData>;

export const FLEET_TYPES = [
  'bulker',
  'car',
  'chassis',
  'dry-van',
  'open-top',
  'reefer',
  'rv',
  'step-deck',
  'tanker',
];

export interface FleetData {
  samsara_id: string;
  name: string;
  license_plate?: string;
  length?: number;
  width?: number;
  over_length_front?: number;
  over_length_back?: number;
  genset?: boolean;
  samsara_name: string;
  samsara_tag_ids: string[];
  status?: string;
  tag_ids: string[];
  type?: string;
  seaspan_unit_type?: string;
}

export type Fleet = DataModel<FleetData>;

export interface CurrentLocation {
  location?: string;
  latitude?: number;
  longitude?: number;
  timeMs?: number;
}

export interface LastMove {
  entity_id: string;
  trip_id: string;
  action: string;
  scheduled_departure_time?: string;
  destination_id?: string;
  scheduled_arrival_time?: string;
}

export interface AssetLocationData {
  samsara_id?: string;
  status?: string;
  type?: string;
  entity_type: string;
  entity_id: string;
  name?: string;
  last_move?: LastMove;
  last_location?: CurrentLocation;
}

export type AssetLocation = DataModel<AssetLocationData>;

export interface FuelSurchargeTypeData {
  name: string;
}

export type FuelSurchargeType = DataModel<FuelSurchargeTypeData>;

export interface GlCodeData {
  name: string;
  gl_code: string;
  location_id?: string;
}

export type GlCode = DataModel<GlCodeData>;

export interface GroupData {
  board?: Board;
  board_id?: string;
  name: string;
  position: number;
  trip_template_ids?: string[];
}

export type Group = DataModel<GroupData>;

export interface GroupView {
  entity_id: string;
  data: {
    name: string;
    samsara_name: string;
    trip_template_ids: string[];
  },
}

export interface NoteTemplateData {
  name: string;
  notes: Descendant[];
}

export type NoteTemplate = DataModel<NoteTemplateData>;

export interface NotificationPreferenceData {
  edi: boolean;
  email: boolean;
  webhook: boolean;
}

export type NotificationPreference = DataModel<NotificationPreferenceData>;


export interface ShipmentTypeData {
  name: string;
}

export type ShipmentType = DataModel<ShipmentTypeData>;

export interface ShippingLineData {
  name: string;
}

export type ShippingLine = DataModel<ShippingLineData>;

export interface TagData {
  id: string;
  name: string;
}

export type Tag = DataModel<TagData>;

export interface TaxData {
  name: string;
  rate: number;
}

export type Tax = DataModel<TaxData>;

export interface TripTemplateData {
  name: string;
  shipments: ShipmentData[];
  trips: TripData[];
  board_ids?: string[];
}

export type TripTemplate = DataModel<TripTemplateData>;

export interface VehicleData {
  samsara_id: string;
  samsara_name: string;
  samsara_tag_ids: string[];
  tag_ids: string[];
}

export type Vehicle = DataModel<DriverData>;

export const INITIAL_SETTINGS: EntityContainer<any> = Object.values(SETTINGS_NAMES)
  .reduce((acc, settingName) => {
    const setting = getSettingFromLocalStorage(settingName);
    return ({
      ...acc,
      [settingName]: {
        lastUpdated: setting?.lastUpdated || null,
        list: setting?.list || [],
      },
    });
  }, {});

export interface SettingsState {
  isLoaded: boolean;
  areSettingsLoading: boolean;
  settings: EntityContainer<any>;
}

export const initialSettingsState: SettingsState = {
  isLoaded: false,
  areSettingsLoading: false,
  settings: INITIAL_SETTINGS,
};
