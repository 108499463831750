import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Move } from '../../../redux/models/trip.models';

interface Props {
  moves: Move[];
}

export default function MoveTimelinessHeaderTooltip({ moves }: Props) {
  return (
    <OverlayTrigger overlay={(
      <Tooltip>
        <div>
          <p className="text-start text-muted ml-2 mt-1">
            {`${moves.length} moves containing load, deliver, dump or tip action types. `}
          </p>
          <ul className="text-start">
            <li className="text-primary">Excessively Early: 30 mins</li>
            <li className="text-info">Early: 15 - 30 mins</li>
            <li className="text-success">On Time: 15 mins early - 10 mins late</li>
            <li className="text-warning">Late: 10 - 30 mins</li>
            <li className="text-danger">Excessively Late: more than 30 mins</li>
          </ul>
        </div>
      </Tooltip>
    )}>
      <span><FontAwesomeIcon icon={faInfoCircle}/></span>
    </OverlayTrigger>
  );
}

