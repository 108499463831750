import { CreateReservationRequest } from '../../redux/slices/seaspan/seaspan.models';
import axiosInstance from '../../utils/axios';
import { EntityServiceProvider } from '../base/EntityServiceProvider';

class SeaspanApiProvider<T> extends EntityServiceProvider<T> {
  createReservation(data: T) {
    return this.api.post(`${this.endpoint.entityPath()}/reservation-request`, data);
  }
}

const SeaspanApi = new SeaspanApiProvider<CreateReservationRequest>(
  'seaspan',
  axiosInstance
);

export default SeaspanApi;