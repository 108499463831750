import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Spinner } from 'react-bootstrap';
import { ControlInput, SelectInput } from '../../../components/shared/Input';

import { selectAllBoards, selectGroups } from '../../../redux/slices/settings';
import { createDropdownOptions } from '../../../utils/core.utils';
import { Group } from '../../../redux/models/settings.models';
import { Board } from '../../../redux/models/board.models';

const CANCEL = 'Cancel';

const PARAM_PROPS = {
  board_id: 'board_id',
  group_id: 'group_id',
  name: 'name',
  shipment_id: 'shipment_id',
};

const PAGE = 'create_trip_template_input';
const getSelectedBoard = (boardId: string, boards: Board[]) => {
  if (!boardId || boardId === '') return null;
  return boards.find((b) => b.entity_id === boardId);
};

const getShowGroupInput = (board: Board | undefined | null) => {
  if (!board) return false;
  const type = board?.data.type;
  if (type === 'shipment' || type === 'asset') return false;
  return true;
}

interface Props {
  shouldShowModal: boolean;
  isSaving: boolean;
  selectedShipmentId?: string;
  showShipment?: boolean;
  saveItem: (name: string, shipmentId: string, boardId: string, groupId: string) => void;
  cancelSave: () => void;
}

function AddTripTemplateModal({
  shouldShowModal,
  isSaving,
  selectedShipmentId,
  showShipment,
  saveItem,
  cancelSave,
}: Props) {
  const nonAssetBoards = useSelector(selectAllBoards);
  const boards = nonAssetBoards.filter((b) => b.data.type !== 'asset');
  const groups = useSelector(selectGroups);
  const [name, setName] = useState('');
  const [shipmentId, setShipmentId] = useState(selectedShipmentId || '');
  const [boardId, setBoardId] = useState('');
  const [groupId, setGroupId] = useState('');
  const boardGroups = groups.filter((group: Group) => group.data.board_id === boardId);
  
  const boardOptions = createDropdownOptions(boards);
  const groupOptions = createDropdownOptions(boardGroups);
  const selectedBoard = getSelectedBoard(boardId, boards);
  const showGroupInput = getShowGroupInput(selectedBoard);
  const isDisabled = name === '' || shipmentId === '' || boardId === '';
  const handleChange = (prop: string, value: string) => {
    if (prop === PARAM_PROPS.board_id) setBoardId(value);
    if (prop === PARAM_PROPS.group_id) setGroupId(value);
    if (prop === PARAM_PROPS.name) setName(value);
    if (prop === PARAM_PROPS.shipment_id) setShipmentId(value);
  };
  const handleResetForm = () => {
    setName('');
    setShipmentId('');
    setBoardId('');
    setGroupId('');
  };
  const handleSave = async () => {
    await saveItem(name, shipmentId, boardId, groupId);
    handleResetForm();
  };
  const handleCancel = () => {
    handleResetForm();
    cancelSave();
  }
  return (
    <Modal
      show={shouldShowModal}
      onHide={cancelSave}
      centered
      data-cy="delete_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <ControlInput
            dataCy={`${PAGE}_${PARAM_PROPS.name}`}
            name={PARAM_PROPS.name}
            type="text"
            page={PAGE}
            value={name}
            labelText="Name"
            handleChange={handleChange}
          />
        </Row>
        {showShipment && (
          <Row>
            <ControlInput
              dataCy={`${PAGE}_${PARAM_PROPS.shipment_id}`}
              name={PARAM_PROPS.shipment_id}
              type="text"
              page={PAGE}
              value={shipmentId}
              labelText="Shipment Id"
              handleChange={handleChange}
            />
          </Row>
        )}
        <Row>
          <SelectInput
            dataCy={`${PAGE}_${PARAM_PROPS.board_id}`}
            name={PARAM_PROPS.board_id}
            page={PAGE}
            options={boardOptions}
            value={boardId}
            labelText="Board"
            handleChange={handleChange}
          />
        </Row>
        {showGroupInput && (
          <Row>
            <SelectInput
              dataCy={`${PAGE}_${PARAM_PROPS.board_id}`}
              name={PARAM_PROPS.group_id}
              page={PAGE}
              options={groupOptions}
              value={groupId}
              labelText="Group"
              handleChange={handleChange}
            />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button id="delete_modal__cancel_btn" variant="secondary" onClick={handleCancel}>
          {CANCEL}
        </Button>
        <Button
          id="add_template_modal_btn"
          data-cy="add_template_modal_btn"
          variant="primary"
          disabled={isDisabled}
          onClick={handleSave}
        >
          Create
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginLeft: '4px' }}
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddTripTemplateModal.defaultProps = {
  selectedShipmentId: '',
  showShipment: true,
};

export default AddTripTemplateModal;
