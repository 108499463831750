import { Badge } from 'react-bootstrap';
import { AlertCircle } from 'react-feather';

const renderShipments = (shipmentNos) => shipmentNos.map(
  (shipmentNo) => <Badge bg="secondary" className="badge-space" key={shipmentNo}>{shipmentNo}</Badge>,
);

const renderStatus = (status) => {
  switch (status) {
    case 'draft':
      return (
        <Badge bg="info" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'sending':
        return (
          <Badge bg="warning" className="badge-space capitalized">
            {status}
          </Badge>
        );
    case 'sent':
      return (
        <Badge bg="primary" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'paid':
      return (
        <Badge bg="success" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'void':
      return (
        <Badge bg="dark" className="badge-space capitalized">
          {status}
        </Badge>
      );
    default:
      return '';
  }
};

export default function InvoiceTableRow({ invoice, prop }) {
  if (prop === 'shipment_no') {
    return renderShipments(invoice.shipment_no);
  }
  if (prop === 'status') {
    const notificationStatus = invoice.edi_notification_status;
    console.log('notificationStatus: ', notificationStatus);
    if (notificationStatus === 'error') {
      return (
        <span>
          {renderStatus(invoice.status)}
          <AlertCircle size={18} className="text-danger" />
        </span>
      )
      
    } 
    return renderStatus(invoice.status);
  }
  return `${invoice[prop]}`;
}
