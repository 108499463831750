import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import { TripTemplate } from '../../redux/models/settings.models';

interface Props {
  templates: TripTemplate[];
  handleAddShipment: () => void;
  handleAddTemplatedShipment: (template: TripTemplate) => void;
}

export default function ShipmentTemplateMenu({ templates, handleAddShipment, handleAddTemplatedShipment }: Props) {
  const { t } = useTranslation();
  return (
    <Dropdown as={ButtonGroup}>
      <AsyncButton title={`${t("Add")}`} spinner="light" handleClick={handleAddShipment} />
      <Dropdown.Toggle split variant="primary" id="add-trip-dropdown" data-cy="boards-add_option_btn" />
      <Dropdown.Menu>
        {templates.map((template, idx) => {
          return (
            <Dropdown.Item onClick={() => handleAddTemplatedShipment(template)} data-cy={`shipments-add__btn__${idx}`}>
              {template?.data?.name || 'No name'}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
