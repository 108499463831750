import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Fetch JSON files
    },
    lng: 'en-US',
    fallbackLng: 'en-US',
    supportedLngs: [
      'en-US',
      'en-GB',
      'es-ES',
      'es-MX',
      'fr-FR',
      'fr-CA'
    ],
    interpolation: {
      escapeValue: false,
    },
  });
