import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ShipmentsTableComponent from './ShipmentsTableComponent';

import { selectAreTripsLoading } from '../../../redux/slices/trips/trips';

import { BILLING_SHIPMENT_TABLE_HEADERS } from './constants/shipmentTable.constants';
import { selectGroupedShipments, selectShipmentFilterParams } from '../../../redux/slices/shipments';
import ShipmentGroupCard from './ShipmentGroupCard';
import { ShipmentGroup, ShipmentListView } from '../../../redux/models/shipment.models';
import { TableHeader } from './models/models';
import ShipmentDataList from '../ShipmentDataList/ShipmentDataList';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';
const useGrid = process.env.REACT_APP_USE_SHIPMENT_DATA_LIST || 'false';

interface Props {
  formattedShipments: ShipmentListView[];
  headers: TableHeader[];
  noShipmentsMessage: string;
  selectedShipments: string[];
  setSelectedShipments: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function ShipmentsTable({
  formattedShipments,
  headers = BILLING_SHIPMENT_TABLE_HEADERS,
  noShipmentsMessage = NO_SHIPMENTS_MESSAGE,
  selectedShipments,
  setSelectedShipments,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const filters = useSelector(selectShipmentFilterParams);
  const groupedShipments: ShipmentGroup[] = useSelector(selectGroupedShipments);
  const groupBy = filters.grouping;
  const openShipmentPage = (id: string) => navigate(`/${orgCode}/shipments/${id}`);

  const selectShipment = (e: React.ChangeEvent<HTMLInputElement>, shipmentId: string) => {
    e.stopPropagation();
    if (selectedShipments.includes(shipmentId)) {
      return setSelectedShipments(selectedShipments.filter((id: string) => id !== shipmentId));
    }
    return setSelectedShipments([...selectedShipments, shipmentId]);
  };
  
  const renderShipmentsTable = (tableShipments: ShipmentListView[]) => {
    if (useGrid === '1') {
      return (
        <ShipmentDataList
          headers={headers}
          shipments={tableShipments}
          selectShipment={selectShipment}
          selectedShipments={selectedShipments}
        />
      )
    }
    return (
      <ShipmentsTableComponent
        areTripsLoading={areTripsLoading}
        headers={headers}
        noShipmentsMessage={noShipmentsMessage}
        formattedShipments={tableShipments}
        openShipmentPage={openShipmentPage}
        selectShipment={selectShipment}
        selectedShipments={selectedShipments}
      />
    );
  }

  return (
    <div className={`${groupBy ? '' : 'bg-white'}`}>
      {groupBy !== '' ? (
        groupedShipments.map((group) => {
          const groupShipments = group.shipments || [];
          return (
            <ShipmentGroupCard
              key={group.id}
              shouldExpandAllItems
              groupBy={groupBy}
              groupByKey={group.id}
              count={groupShipments.length}
            >
              {renderShipmentsTable(groupShipments)}
            </ShipmentGroupCard>
          )
        })
      ) : (
        renderShipmentsTable(formattedShipments)
      )}
    </div>
  );
}