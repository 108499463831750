/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Invoice } from '../redux/models/invoice.models';
import { ApiQuery } from '../redux/models/network.models';
import { Shipment, ShipmentListFilters } from '../redux/models/shipment.models';
import { Move, Trip } from '../redux/models/trip.models';
import axiosInstance from '../utils/axios';

import { AquadashServiceConfigProvider } from './AquadashServiceConfigProvider';

export class AquadashServiceApiProvider {
  static getBoards() {
    return axiosInstance.get(AquadashServiceConfigProvider.getBoards());
  }

  static getTrips(boardId: string, filters: any) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTrips(boardId, filters));
  }

  static getTripDetails(tripId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTripDetails(tripId));
  }

  static getTripRoute(routeId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTripRoute(routeId));
  }

  static getTripDocument(documentId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getTripDocument(documentId));
  }

  static getGroups(boardId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getGroups(boardId));
  }

  static syncDepartmentCodes() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncDepartmentCodes());
  }

  static syncGLCodes() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncGLCodes());
  }

  static syncGroups() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncGroups());
  }

  static getGroupDetails(groupId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getGroupDetails(groupId));
  }

  static getAppData() {
    return axiosInstance.get(AquadashServiceConfigProvider.getAppData());
  }

  static syncDrivers() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncDrivers());
  }

  static getDrivers() {
    return axiosInstance.get(AquadashServiceConfigProvider.getDrivers());
  }

  static syncVehicles() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncVehicles());
  }

  static getVehicles() {
    return axiosInstance.get(AquadashServiceConfigProvider.getVehicles());
  }

  static syncFleet() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncFleet());
  }

  static getFleet() {
    return axiosInstance.get(AquadashServiceConfigProvider.getFleet());
  }

  static getFleetLocations(destinationId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getFleetLocations(destinationId));
  }

  static getShipments(filters: ShipmentListFilters) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipments(filters));
  }

  static getShipmentsAllDetails(filters: ShipmentListFilters) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentsAllDetails(filters));
  }

  static getShipmentDetails(shipmentId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentDetails(shipmentId));
  }

  static updateShipment(shipment: Shipment) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateShipment(shipment.entity_id), shipment);
  }

  static syncAddresses() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncAddresses());
  }

  static getAddresses() {
    return axiosInstance.get(AquadashServiceConfigProvider.getAddresses());
  }

  static getContacts() {
    return axiosInstance.get(AquadashServiceConfigProvider.getContacts());
  }

  static syncContacts() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncContacts());
  }

  static getBillingItems() {
    return axiosInstance.get(AquadashServiceConfigProvider.getBillingItems());
  }

  static syncBillingItems() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncBillingItems());
  }

  static getBillingRules() {
    return axiosInstance.get(AquadashServiceConfigProvider.getBillingRules());
  }

  static syncBillingRules() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncBillingRules());
  }

  static getBillingTypes() {
    return axiosInstance.get(AquadashServiceConfigProvider.getBillingTypes());
  }

  static syncBillingTypes() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncBillingTypes());
  }

  static getProducts() {
    return axiosInstance.get(AquadashServiceConfigProvider.getProducts());
  }

  static getTags() {
    return axiosInstance.get(AquadashServiceConfigProvider.getTags());
  }

  static getUoms() {
    return axiosInstance.get(AquadashServiceConfigProvider.getUoms());
  }

  static syncUoms() {
    return axiosInstance.get(AquadashServiceConfigProvider.syncUoms());
  }

  static createInvoice(shipmentIds: string[]) {
    return axiosInstance({
      method: 'POST',
      url: AquadashServiceConfigProvider.createInvoice(),
      data: { shipment_ids: shipmentIds },
      validateStatus: () => true,
    })
  }

  static getMentions() {
    const filters = {
      "filters": [
        {
          "field": "data.target_entity",
          "op": "eq",
          "value": "move"
        }
      ],
      "operator": "AND"
    };
    return axiosInstance({
      method: 'POST',
      url: AquadashServiceConfigProvider.getMentions(),
      data: filters,
      validateStatus: () => true,
    });
  }

  static getInvoice(invoiceId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getInvoice(invoiceId));
  }

  static updateInvoice(invoice: Invoice) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateInvoice(invoice.entity_id), invoice);
  }

  static sendInvoice(invoice: Invoice) {
    const url = `${AquadashServiceConfigProvider.updateInvoice(invoice.entity_id)}/send`;
    return axiosInstance.put(url, invoice);
  }

  static updateAccessorials(shipmentId: string) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateAccessorials(shipmentId));
  }

  static deleteShipmentInvoice(shipmentId: string) {
    return axiosInstance.delete(AquadashServiceConfigProvider.deleteShipmentInvoice(shipmentId));
  }

  static createTrip(body: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.createTrip(), body);
  }

  static createTripFromTemplate(templateId: string, data: any) {
    // const data = createTripFromTemplateParams(date, notesTemplateId, defaultNotes);
    return axiosInstance.post(AquadashServiceConfigProvider.createTripFromTemplate(templateId), data);
  }

  static createMove(body: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.createMove(), body);
  }

  static updateTrip(trip: any) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateTrip(trip.entity_id), trip);
  }

  static updateMoves(moves: Move[]) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateBatchMoves(), moves);
  }

  static sendTripRoute(tripId: string) {
    return axiosInstance.post(AquadashServiceConfigProvider.sendTripRoute(tripId));
  }

  static deleteTrip(tripId: string) {
    return axiosInstance.delete(AquadashServiceConfigProvider.deleteTrip(tripId));
  }

  static addShipmentsToTrip(tripId: string, tripDetails: Trip) {
    return axiosInstance.put(AquadashServiceConfigProvider.addShipmentsToTrip(tripId), tripDetails);
  }

  static sendETAEMail(tripIds: string[]) {
    return axiosInstance.post(AquadashServiceConfigProvider.sendETAEMail(), tripIds);
  }

  static sendFerryEmail(tripIds: string[]) {
    return axiosInstance.post(AquadashServiceConfigProvider.sendFerryEmail(), tripIds);
  }

  static searchSettings(settingName: string, searchStr: string, apiQueryParam: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.searchSettings(settingName, searchStr, apiQueryParam));
  }

  static findSettings(entityPath: string, query: ApiQuery) {
    return axiosInstance.post(`/${entityPath}/find`, query);
  }

  static getTemplates() {
    return axiosInstance.get(AquadashServiceConfigProvider.getTemplates());
  }

  static getNoteTemplates() {
    return axiosInstance.get(AquadashServiceConfigProvider.getNoteTemplates());
  }

  static deleteTemplate(entityId: string) {
    return axiosInstance.delete(AquadashServiceConfigProvider.deleteTemplate(entityId));
  }

  static createTemplate(params: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.createTemplate(), params);
  }

  static uploadFile(file: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.uploadFile(), file);
  }

  static downloadFile(file: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.downloadFile(), file);
  }

  static deleteFile(file: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.deleteFile(), file);
  }
}
