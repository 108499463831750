import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Row, Col, Form, Card, Badge } from "react-bootstrap"
import { EntityContainer } from "../../../redux/models/core.models"
import { selectFleet } from "../../../redux/slices/settings"
import { DateService } from "../../../utils/dateService"
import { formatEnumName } from "../asset-tracker.utils"

const NAME = 'Assets';

const statusColors: EntityContainer<string> = {
  active: "success",
  maintenance: "warning",
  inactive: "danger",
}

export default function AllAssets() {
  const { orgCode } = useParams();
  const fleet = useSelector(selectFleet);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");

  const filteredAssets = fleet.filter((fleetItem) => {
    const matchesSearch = fleetItem.data.name?.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesStatus = statusFilter === "all" || fleetItem.data?.status === statusFilter
    const matchesType = typeFilter === "all" || fleetItem.data?.type === typeFilter
    return matchesSearch && matchesStatus && matchesType;
  })

  return (
    <div className="min-vh-100">
      <h3>{NAME}</h3>
      <Row className="mb-4">
        <Col xs={12} md={6} className="mb-3 mb-md-0">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search assets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3} className="mb-3 mb-md-0">
          <Form.Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="all">All Statuses</option>
            <option value="active">Active</option>
            <option value="maintenance">Maintenance</option>
            <option value="inactive">Inactive</option>
          </Form.Select>
        </Col>
        <Col xs={12} md={3}>
          <Form.Select value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>
            <option value="all">All Types</option>
            <option value="reefer">Reefer</option>
            <option value="tanker">Tanker</option>
          </Form.Select>
        </Col>
      </Row>

      <Row xs={1} md={2} lg={3} className="g-4">
        {filteredAssets.map((asset) => {
          const status = asset.data.status || '';
          const type = asset.data.type || '';
          return (
            <Col key={asset.entity_id}>
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Link
                        to={`/${orgCode}/assets/fleet/${asset.entity_id}`}
                      >
                      <Card.Title className="h6 mb-0">
                        {asset.data.name || ''}
                      </Card.Title>
                    </Link>
                    {status !== '' && (
                      <Badge bg={statusColors[asset.data.status || '']} pill>
                        {formatEnumName(status)}
                      </Badge>
                    )}
                    {type !== '' && (
                      <Badge bg={statusColors[asset.data.status || '']} pill>
                        {formatEnumName(type)}
                      </Badge>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="text-muted small">{asset.data.status || ''}</span>
                  </div>
                  <Card.Text className="text-muted small mb-0">
                    {`Last updated: ${DateService.toRelativeTime(asset.modified_at || '')}`}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
      
      {filteredAssets.length === 0 && (
        <p className="text-center text-muted mt-5">No assets found matching the current filters.</p>
      )}

    </div>
  )
}