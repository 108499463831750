/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row } from 'react-bootstrap';
import { Fleet } from '../../../redux/models/settings.models';
import { CreateReservationRequest } from '../../../redux/slices/seaspan/seaspan.models';
import JobForm from './JobForm';
import { SelectInput } from '../../../components/shared/Input';
import { createDropdownOptions } from '../../../utils/core.utils';

interface Props {
  tripId: string;
  trailerId: string;
  shipmentIds: string[];
  reservation: CreateReservationRequest;
  trailers: Record<string, Fleet>;
  shipmentOptions: any;
  onSave: () => void;
  selectTrailer: (trailerId: string) => void;
  selectShipment: (shipmentIds: string[]) => void;
}

export default function NewJobCard({
  reservation, tripId, trailers, trailerId, shipmentOptions, shipmentIds, onSave, selectTrailer, selectShipment
}: Props) {
  const { t } = useTranslation();
  const trailerOptions = Object.values(trailers).filter((tr) => tr !== undefined);
  const fleetOptions = createDropdownOptions(trailerOptions);
  const selectedTrailer = (_: string, tId: string) => {
    selectTrailer(tId);
  };
  const selectedShipments = (_: string, shIds: string[]) => {
    selectShipment(shIds);
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Card.Title>{`${t("Trailers")}:`}</Card.Title>
          <SelectInput
            name="trailer_id"
            page="{page}"
            value={trailerId || ''}
            options={fleetOptions}
            shouldHaveMargin={false}
            handleChange={selectedTrailer}
            dataCy="move_trailer_id"
            labelText=""
          />
        </Row>
        <Row className="mt-2">
          <Card.Title>{`${t("Shipments")}:`}</Card.Title>
          <SelectInput
            multiple
            name="shipment_ids"
            page="{page}"
            value={shipmentIds || []}
            options={shipmentOptions}
            shouldHaveMargin={false}
            handleChange={selectedShipments}
            dataCy="move_shipment_ids"
            labelText=""
          />
        </Row>
        <Row>
          <JobForm
            tripId={tripId}
            trailerId={trailerId}
            shipmentIds={shipmentIds}
            reservation={reservation}
            onSave={onSave}
          />
        </Row>
      </Card.Body>
    </Card>
  )
}